const projects = [
  {
    title: "HeyChat",
    image: "./project-images/HeyChat.png",
    shortDesription: [
      "An online chatting user app",
      "Developed using ReactJs, NodeJs and MongoDB as primary technologies",
    ],
    liveProject: "https://heychat.onrender.com",
  },
  {
    title: "Elibrary",
    image: "./project-images/ELibrary.png",
    shortDesription: [
      "A library management web application",
      "Developed using Java, SpringBoot and MySql as primary technologies",
    ],
    liveProject: "https://elibrary-tnx8.onrender.com/",
  },
  {
    title: "mFor",
    image: "./project-images/ELibrary.png",
    shortDesription: ["A music app", "Coming Soon"],
    liveProject: "",
  },
];

export default projects;
