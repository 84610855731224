const education = [
  {
    key: 1,
    logo: "JSpiders",
    name: "Jspiders JNTU",
    course: "Java Full Stack",
    year: "2022-23",
    description: [
      "Completed java full stack training",
      "Got * rating in all subject",
      "Participated in interview drive and got placed in first attempt",
    ],
  },
  {
    key: 2,
    logo: "VDC",
    name: "Vaagdeevi Degree College Mancherial",
    course: "BSc MPCs",
    year: "2019-22",
    description: [
      "Attended graduation in Vaagdevi Degree College Mancherial",
      "Specialized in Physics, Maths and Computer Science",
      "Participate in TASK interviews and got placement opportunity in Infosys as Associate Engineer",
      "Recieved Best Student Award from college",
      "Got 8.02 as final CGPA",
    ],
  },
  {
    key: 3,
    logo: "SHJC",
    name: "Sri Harsha Jr. College Mancherial",
    course: "BSc MPCs",
    year: "2017-19",
    description: [
      "Attended intermediate education in SRI HARSH JR. COLLEGE",
      "Did my 10+2 with Maths, Physics and Chemistry",
      "Scored 914/1000 in board Exams",
    ],
  },
  {
    key: 4,
    logo: "SSC",
    name: "ZPSS High School Kistampet",
    course: "Secondary School Education",
    year: "2012-17",
    description: [
      "Done my Secondary School Education in ZPSS High School Kistampet",
      "Got 9.2/10 GPA in board exams",
      "Was the topper in School.",
      "Recieved B.C Student award from B.C society.",
    ],
  },
];

export default education;
