import React from "react";
import "./style.css";

function ProjectCard({ project }) {
  return (
    <div className="project-card">
      <div className="project-image">
        <img src={project.image} alt="projectImage" />
      </div>
      <div className="project-details">
        <h1>{project.title}</h1>
        <div className="short-description">
          <ul>
            {project.shortDesription.map((line, index) => {
              return <li key={index + 1}>{line}</li>;
            })}
            <li>
              Live Project: <br />
              <a href={project.liveProject}>{project.liveProject}</a>{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
