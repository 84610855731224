import React from "react";
import "./Skillbar.css";

function Skillbar({ skill }) {
  return (
    <div className="skillbar">
      <div className="skill-image-container">
        <img src={`${skill.image}`} alt="" />
      </div>
      <div className="skill-content">
        <h3>{skill.name}</h3>
      </div>
    </div>
  );
}

export default Skillbar;
