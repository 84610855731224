import React from "react";
import { Footer, Home1, Navbar, QuickIntro, Skills } from "../components";
import { BackgroundBeams } from "../ui/background-beams";

function Home() {
  return (
    <>
      <BackgroundBeams className="beam-background" />
      <Navbar />
      <Home1 />
      <QuickIntro />
      <Skills />
      <Footer />
    </>
  );
}

export default Home;
