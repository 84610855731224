import React, { useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./style.css";

function Image({ image }) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <>
      <div className="image-placeholder">
        <img
          src={image.src}
          alt={image.alt}
          className={isImageLoaded ? "image-visible" : "image-invisible"}
          onLoad={() => {
            console.log("Image loaded");
            setIsImageLoaded(true);
          }}
        />
      </div>
    </>
  );
}

export default Image;
