import React from "react";
import { Footer } from "../components";
import Navbar from "../components/Navbar/Navbar";
import ProjectCard from "../components/ProjectCard/ProjectCard";
import projects from "./project";

function Projects() {
  console.log(projects);
  return (
    <div className="project-container">
      <Navbar />
      <h1 className="project-h1">Projects</h1>
      <div className="project-card-container">
        {projects.map((project, index) => {
          return <ProjectCard key={index + 1} project={project} />;
        })}
      </div>
      <Footer />
    </div>
  );
}

export default Projects;
