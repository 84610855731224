import React from "react";
import { Footer, Navbar, TimelineCard } from "../components/index";
import education from "./education";

function Timeline() {
  return (
    <>
      <Navbar />
      <div className="time-line-container">
        <h1>What did i do?</h1>
        {console.log(education)}
        {education.map((edu, index) => {
          return <TimelineCard key={index} edu={edu} />;
        })}
      </div>
      <Footer />
    </>
  );
}

export default Timeline;
