import React from "react";
import "./timelinecard.css";

function TimelineCard({ edu }) {
  return (
    <div className="timeline-card">
      <div className="logo">
        <h1>{edu.logo}</h1>
      </div>
      <div className="details-card">
        <div className="college-details">
          <h2>{edu.name}</h2>
          <h3>{edu.course}</h3>
          <h4>{edu.year}</h4>
        </div>
        <ul className="details-list">
          {edu.description.map((des) => {
            return <li>{des}</li>;
          })}
        </ul>
      </div>
    </div>
  );
}

export default TimelineCard;
