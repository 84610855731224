import React from "react";
import "./quickintro.css";

function QuickIntro({ setHome }) {
  return (
    <>
      <div className="quick-intro" id="quick-intro">
        <h1>QUICK INTRO</h1>
        <div className="container">
          <div className="content">
            <p>
              I’m a curious learner of new Technologies since my childhood. I
              choose IT as my career to develop new technologies and upgrade
              myself into these new technologies as I develop and Learn.
            </p>
            <p>
              Learnt most of the Full Stack Technologies and made some projects
              with those tools. Loves to play around with new and interesting
              technologies and gadgets
            </p>
            <p></p>
          </div>
          <div className="image-container">
            <img src="./profile.png" alt="" srcSet="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default QuickIntro;
