const skills = [
  {
    image: "./skills-png/icons8-html-48.png",
    name: "HTML",
  },

  { image: "./skills-png/icons8-css-48.png", name: "CSS" },
  { image: "./skills-png/icons8-javascript-48.png", name: "JavaScript" },
  { image: "./skills-png/icons8-react-80.png", name: "React" },
  { image: "./skills-png/icons8-bootstrap-48.png", name: "BootStrap" },
  { image: "./skills-png/icons8-figma-48.png", name: "Figma" },
  { image: "./skills-png/icons8-c-48(1).png", name: "C" },
  { image: "./skills-png/icons8-c-64.png", name: "C#" },
  { image: "./skills-png/icons8-c-48.png", name: "C++" },
  { image: "./skills-png/icons8-java-48.png", name: "Java" },
  { image: "./skills-png/icons8-python-48.png", name: "Python" },
  { image: "./skills-png/icons8-javascript-48.png", name: "JavaScript" },
  { image: "./skills-png/icons8-nodejs-48.png", name: "Node Js" },
  { image: "./skills-png/icons8-express-js-48.png", name: "Express Js" },
  { image: "./skills-png/icons8-spring-boot-48.png", name: "Spring Boot" },
  { image: "./skills-png/icons8-sql-60.png", name: "SQL" },
  { image: "./skills-png/icons8-mysql-48.png", name: "MySql" },
  {
    image:
      "./skills-png/icons8-mongodb-a-cross-platform-document-oriented-database-program-48.png",
    name: "MongoDB",
  },
];

export default skills;
