import emailjs from "emailjs-com";
import React from "react";
import "../Footer/footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="menu-container">
          <ul className="menu explore">
            <h4>Explore</h4>
            <li className="menu-item">
              <a href="/#home" className="menu-link">
                Home
              </a>
            </li>
            <li className="menu-item">
              <a href="/skills" className="menu-link">
                Skills
              </a>
            </li>
            <li className="menu-item">
              <a href="/timeline" className="menu-link">
                Timeline
              </a>
            </li>
            <li className="menu-item">
              <a href="/projects" className="menu-link">
                Projects
              </a>
            </li>
            {/* <li className="menu-item">
              <a href="/about" className="menu-link">
                About
              </a>
            </li> */}
          </ul>
          <ul className="menu">
            <h4>Support Me</h4>
            <li className="menu-item">Instagram</li>
            <li className="menu-item">Facebook</li>
            <li className="menu-item">Github</li>
            <li className="menu-item">LinkedIn</li>
          </ul>
        </div>
        <div className="contact-form" id="contact-me">
          <form
            action=""
            onSubmit={(event) => {
              event.preventDefault();
              console.log(event.target.from_name.value);
              emailjs
                .send(
                  "service_jytb2uv",
                  "template_j4pma7b",
                  {
                    from_name: event.target.from_name.value,
                    from_email: event.target.from_email.value,
                    message: event.target.message.value,
                  },
                  "VMjLoK-e0rh1dk0Co"
                )
                .then(() => {
                  alert("Message Sent Successfully");
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            <h1>Contact Me</h1>
            <div className="form-field">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input type="text" id="name" name="from_name" className="input" />
            </div>
            <div className="form-field">
              <label htmlFor="name" className="form-label">
                Email
              </label>
              <input
                type="email"
                id="name"
                name="from_email"
                className="input"
              />
            </div>
            <div className="form-field">
              <label
                htmlFor="text-area"
                className="form-label"
                name="html_message"
              >
                Message
              </label>
              <textarea
                name="message"
                id="text-area"
                cols="30"
                rows="5"
                className="input"
              ></textarea>
            </div>
            <button className="btn" type="submit">
              Send
            </button>
          </form>
        </div>
      </div>
      <div className="copy-right">&copy; RajkumarBallepu 2024</div>
    </div>
  );
}

export default Footer;
