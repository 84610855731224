"use client";
import {
  faFacebookF,
  faGithub,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Image from "../Image/Image";
import "./home.css";
import image from "./image.png";

function home() {
  return (
    <div className="home" id="home">
      <div className="header">
        <div className="image-container">
          {/* <img src={image} className="img" alt="" /> */}
          <Image className="img" image={{ src: image, alt: "profile-pic" }} />
        </div>
        <div className="content">
          <p>
            Hello I'm <br /> <span className="special">Raj Kumar Ballepu</span>
          </p>
          <div className="role-content">
            <p>A developer who</p>
            <h1>
              Creates Innovative <br></br>
              <span className="special">Applications...</span>
            </h1>
            <p>The Applications which resolve real world problems.</p>
            <div className="social-icons">
              <a
                href="https://instagram.com/rajkumarballepu"
                className="social-link"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://www.linkedin.com/in/raj-kumar-ballepu-0ba355233/"
                className="social-link"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <a
                href="https://github.com/rajkumarballepu"
                className="social-link"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a href="#s" className="social-link">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </div>
            <a href="./resume.docx" className="btn" download={true}>
              Download Resume
            </a>
          </div>
        </div>
      </div>
      <div className="scroll-icon">
        <a href="#quick-intro">
          <FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon>
        </a>
      </div>
    </div>
  );
}

export default home;
