import React from "react";
import { Footer, Navbar, Skillbar } from "../components";
import skills from "./skill";

function Skills() {
  return (
    <>
      {console.log(skills)}
      <Navbar />
      <div className="skillbar-container">
        <h1>My Skills</h1>

        {skills.map((skill, index) => {
          return <Skillbar key={index + 1} skill={skill} />;
        })}
      </div>
      <Footer />
    </>
  );
}

export default Skills;
